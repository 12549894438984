<template>
  <main>
    <div class="navbar-offset marketplace-main-info mb-5">
      <div class="container pt-lg-7 pt-md-3 pt-0 px-1 px-md-0">
        <div class="row">
          <div class="col d-block d-md-none">
            <h3 class="mt-1-5 mb-0">
              One-Hair GmbH
            </h3>
          </div>
          <div class="col-12 px-0 pt-3 d-none d-md-block d-lg-none">
            <div class="d-flex justify-content-between">
              <div class="col-8 px-0 d-flex">
                <img
                  :src="require(`@/assets/images/salon-partners/logo-partners.jpg`)"
                  height="80px"
                  width="160px"
                  alt=""
                  class="mb-2 ml-1 marketplace-img"
                />
                <h3 class="my-1 ml-2">
                  One-Hair GmbH
                </h3>
              </div>
              <div class="mr-1">
                <button class="btn btn-lg btn-block btn-primary mb-2 w-100">
                  <span>{{ $t('market.continue-booking') }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-3 d-none d-md-block px-0">
            <div class="d-block d-md-none d-lg-block">
              <img
                :src="require(`@/assets/images/salon-partners/logo-partners.jpg`)"
                height="150px"
                alt=""
                class="w-100 mb-2 marketplace-img d-none d-lg-block"
              />
              <h3 class="my-2">
                One-Hair GmbH
              </h3>
            </div>
          </div>

          <div class="col-12 col-lg ml-lg-5 px-0 ml-0">
            <div class="col px-0 mb-lg-2 mb-md-1 mb-0 d-flex marketFilters">
              <div class="col-lg-12 d-flex pl-0-2 mt-1 mt-md-0 mb-0-5 mb-md-0 ml-0-5 pr-0 pr-md-1-5">
                <div id="multiselect" tabindex="-1" class="multiselect quiff">
                  <div class="form-group mb-0 mb-md-1-5 has-search d-flex">
                    <input type="text" class="form-control mr-0" :placeholder="$t('market.search')" />
                    <font-awesome-icon
                      :icon="['far', 'search']"
                      class="icon-search form-control-feedback"
                    ></font-awesome-icon>
                  </div>
                </div>
                <i class="filter-icon d-md-none mt-1-5 mb-3 mr-1-5 filterIcon"></i>
              </div>
            </div>

            <div class="col px-0 d-flex flex-wrap">
              <div
                class="col-lg-4 col-6 px-0-8 mb-lg-2 mb-md-3 mb-5 productCard"
                @mouseenter.stop="mouseOver($event)"
                @mouseleave="onDeactive($event)"
              >
                <div class="py-1-5 px-1 bg-white card">
                  <img
                    src="@/assets/images/salon-partners/Elektro.png"
                    class="mb-1 product-images-fit"
                    width="100%"
                    height="300px"
                    alt="product-image"
                  />

                  <span class="w-100 item-title mt-2 mt-md-0 poppins-font item-text text-truncate">Elektro</span>
                  <a
                    :href="'https://coiffeurbedarf.ch/k4/Elektro'"
                    target="_blank"
                    class="btn btn-lg btn-primary mt-lg-2 mt-1 viewMoreBtn btn-block primary-shadow px-0-3 px-md-1-3"
                  >
                    <span class="text-nowrap text-white">{{ $t('market.view-more') }}</span>
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 col-6 px-0-8 mb-lg-2 mb-md-3 mb-5 productCard"
                @mouseenter.stop="mouseOver($event)"
                @mouseleave="onDeactive($event)"
              >
                <div class="py-1-5 px-1 bg-white card">
                  <img
                    src="@/assets/images/salon-partners/Scheren-Messer.png"
                    class="mb-1 product-images-fit"
                    width="100%"
                    height="300px"
                    alt="product-image"
                  />
                  <span class="w-100 item-title mt-2 mt-md-0 poppins-font item-text text-truncate">Scheren-Messer</span>
                  <a
                    href="https://coiffeurbedarf.ch/k5/Scheren-Messer"
                    target="_blank"
                    class="btn btn-lg btn-primary mt-lg-2 mt-1 viewMoreBtn btn-block primary-shadow px-0-3 px-md-1-3"
                  >
                    <span class="text-nowrap text-white">{{ $t('market.view-more') }}</span>
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 col-6 px-0-8 mb-lg-2 mb-md-3 mb-5 productCard"
                @mouseenter.stop="mouseOver($event)"
                @mouseleave="onDeactive($event)"
              >
                <div class="py-1-5 px-1 bg-white card">
                  <img
                    src="@/assets/images/salon-partners/Einrichtung.png"
                    class="mb-1 product-images-fit"
                    width="100%"
                    height="300px"
                    alt="product-image"
                  />

                  <span class="w-100 item-title mt-2 mt-md-0 poppins-font item-text text-truncate">Einrichtung</span>

                  <a
                    :href="'https://coiffeurbedarf.ch/k6/Einrichtung'"
                    target="_blank"
                    class="btn btn-lg btn-primary mt-lg-2 mt-1 viewMoreBtn btn-block primary-shadow px-0-3 px-md-1-3"
                  >
                    <span class="text-nowrap text-white">{{ $t('market.view-more') }}</span>
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 col-6 px-0-8 mb-lg-2 mb-md-3 mb-5 productCard"
                @mouseenter.stop="mouseOver($event)"
                @mouseleave="onDeactive($event)"
              >
                <div class="py-1-5 px-1 bg-white card">
                  <img
                    src="@/assets/images/salon-partners/Zubehör-Arbeitsmaterial.png"
                    class="mb-1 product-images-fit"
                    width="100%"
                    height="300px"
                    alt="product-image"
                  />

                  <span class="w-100 item-title mt-2 mt-md-0 poppins-font item-text text-truncate"
                    >Zubehör-Arbeitsmaterial</span
                  >
                  <a
                    :href="'https://coiffeurbedarf.ch/k3/Zubeh%C3%B6r-Arbeitsmaterial'"
                    target="_blank"
                    class="btn btn-lg btn-primary mt-lg-2 mt-1 viewMoreBtn btn-block primary-shadow px-0-3 px-md-1-3"
                  >
                    <span class="text-nowrap text-white">{{ $t('market.view-more') }}</span>
                  </a>
                </div>
              </div>
              <div
                class="col-lg-4 col-6 px-0-8 mb-lg-2 mb-md-3 mb-5 productCard"
                @mouseenter.stop="mouseOver($event)"
                @mouseleave="onDeactive($event)"
              >
                <div class="py-1-5 px-1 bg-white card">
                  <img
                    src="@/assets/images/salon-partners/Werbematerialien.png"
                    class="mb-1 product-images-fit"
                    width="100%"
                    height="300px"
                    alt="product-image"
                  />

                  <span class="w-100 item-title mt-2 mt-md-0 poppins-font item-text text-truncate"
                    >Werbematerialien</span
                  >
                  <a
                    :href="'https://coiffeurbedarf.ch/k479/Werbematerialien'"
                    target="_blank"
                    class="btn btn-lg btn-primary mt-lg-2 mt-1 viewMoreBtn btn-block primary-shadow px-0-3 px-md-1-3"
                  >
                    <span class="text-nowrap text-white">{{ $t('market.view-more') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    mouseOver(event) {
      if (event.target.className.includes('productCard')) {
        event.target.classList.add('cardIsActive')
      }
    },
    onDeactive(event) {
      if (event.target.className.includes('productCard')) {
        event.target.classList.remove('cardIsActive')
      }
    }
  }
}
</script>

<style scoped>
.productCard {
  height: 370px !important;
}
</style>
